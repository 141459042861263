import * as React from "react"
import styled, { keyframes } from "styled-components"
import '../components/layout'
import { RandomReveal } from 'react-random-reveal'

const Title = styled.h1 `
  font-size: 16px;
  color: black;
  font-family: 'Terminus';
  font-weight: 500;
  color: white;
  text-transform: uppercase;
`
const Center = styled.div `
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const fadeIn = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`

const Logo = styled.img `
  margin-bottom: 24px;
  animation-name: ${fadeIn};
  animation-duration: 2s;
`

const IndexPage = () => {
  return (
    <main>
        <Center>
          <Logo src={`/images/logo-temp.png`}/>
          <Title>
            <RandomReveal
              isPlaying
              duration={1}
              revealDuration={1}
              characters="coming soon"
              onComplete={() => ({shouldRepeat: false, delay: 3})}
            />
          </Title>
        </Center>
    </main>
  )
}

export default IndexPage

export const Head = () => <title>Coming Soon</title>